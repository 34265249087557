import React from "react";
import Layout from "ps-components/Layout/Layout.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import termsStyle from "ps-assets/jss/termsOfUsageStyle.jsx";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import classNames from "classnames";
import { Helmet } from "react-helmet";

let url = process.env.BASE_URL;
const getMuiTheme = () =>
  createMuiTheme({
    typography: {
      useNextVariants: true
    },
    overrides: {
      MUIDataTable: {
        paper: {
          boxShadow: `none`,
          width: `92%`,
          marginLeft: `3%`,
          fontSize: `15px`
        },
        responsiveScroll: {
          maxHeight: `auto !important`
        }
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          color: `#000`,
          fontSize: `15px`,
          fontWeight: `bold`
        },
        root: {
          paddingLeft: `15px !important`,
          paddingRight: `15px !important`,
          fontSize: `15px`,
          whiteSpace: `nowrap`
        }
      },
      MUIDataTableBodyRow: {
        root: {
          fontSize: `15px !important`,
          "&:nth-child(odd)": {
            backgroundColor: `rgba(0, 0, 0, 0.07)`
          },
          "&:hover": {
            backgroundColor: `transparent !important`,
            "&:nth-child(odd)": {
              backgroundColor: `rgba(0, 0, 0, 0.07) !important`
            }
          }
        }
      },
      MUIDataTableBody: {
        emptyTitle: {
          textTransform: `none !important`
        }
      },
      MUIDataTableBodyCell: {
        root: {
          fontSize: `15px !important`,
          paddingLeft: `26px !important`,
          paddingRight: `26px !important`
        }
      },
      MUIDataTableSelectCell: {
        root: {
          display: `none`
        }
      }
    }
  });

const options = {
  selectableRows: false,
  sort: false,
  filter: false,
  search: false,
  download: false,
  print: false,
  viewColumns: false,
  responsive: `scroll`,
  pagination: false,
  textLabels: {
    body: {
      noMatch: ``
    }
  }
};

const HealthCheckupPackages = ({ classes, data, pageContext }) => {
  var image = data.allStrapiImages.edges[0].node.content.childImageSharp.fluid;
  var tableData = data.allStrapiDocuments.edges[0].node.sections.map(
    section => {
      var temp = [];
      temp.push(section.title);
      temp.push(section.description);
      return temp;
    }
  );

  const urlPath = data.strapiDocuments.type;
  
  return (
    <Layout image2={image} small>
      <Helmet key="helmetTags">
        <title>Health checkup packages | StarHealth.in</title>
      </Helmet>
      {process.env.ENABLE_HELMET == `true` && (
        <Helmet key="helmetTags">
          <link
            rel="canonical"
            href={url + `health-checkup-packages-` + urlPath}
          />
          <meta name="title" content="Health checkup packages | StarHealth.in" />
          <meta name="twitter:title" content="Health checkup packages | StarHealth.in" />
          <meta
            name="description"
            content="Star Health Insurance Health checkup packages"
          />
          <meta
            name="keywords"
            content="Buy Online Health Insurance Plans, Health Insurance, Accident Care, Overseas Travel, Insurance Policy, Insurance Agent Corner, Star Health and Allied Insurance Company Limited, Chennai, India, Portability, Star Health Products, Renew  Policy Online,Star Health Locate us, Health Insurance Specialist In India."
          />
          <meta property="og:title" content="Health checkup packages | StarHealth.in" />
          <meta property="og:description" content="Star Health Insurance Health checkup packages" />
          <meta property="og:url" content={url + `health-checkup-packages-` + urlPath}/>
          <meta name="Health checkup packages | StarHealth.in" />
          <meta name="twitter:description" content="Star Health Insurance Health checkup packages" />
          <meta property="twitter:url" content={url + `health-checkup-packages-` + urlPath} />
        </Helmet>
      )}
      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ marginBottom: `100px` }}
      >
        <div className={classes.section}>
          <div
            className={classes.container}
            style={{ backgroundColor: `white` }}
          >
            <h1  className={classes.h1title} style={{ textAlign: `center` }}>
              <b>Health Checkup Packages-{pageContext.title}</b>
            </h1>
            <MuiThemeProvider theme={getMuiTheme()}>
              <MUIDataTable
                // key={30}
                data={tableData}
                columns={[`Package and Amount`, `Tests`]}
                options={options}
              />
            </MuiThemeProvider>
          </div>
        </div>
      </div>
    </Layout>
  );
};

HealthCheckupPackages.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  pageContext: PropTypes.object
};

export default withStyles(termsStyle)(HealthCheckupPackages);

export const healthCheckupPackagesQuery = graphql`
  query healthCheckupPackages($type: String!) {
    allStrapiImages(filter: { category: { eq: "health_checkup_pages" } }) {
      edges {
        node {
          content {
            publicURL
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    allStrapiDocuments(filter: { type: { eq: $type } }) {
      edges {
        node {
          type
          sections {
            title
            description
          }
        }
      }
    }
    strapiDocuments(type: {eq: $type }) {
      type
    }
  }
`;
